import React, { useState, useEffect } from "react";

import "../style.css";

function Index() {
  let [number, setNumber] = useState(0);
  let [count, setCount] = useState(0);
  let [count2, setCount2] = useState(0);

  useEffect(() => {
    const id = setInterval(() => setNumber((number) => number + 1), 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  let handleClick = (e) => {
    console.log("this is working fine");
    e.target.style.backgroundColor = "green";
    console.log(e.target);
  };

  return (
    <div>
      <button
        style={{ height: 100, width: 100 }}
        onClick={(e) => [setCount(count + 1), handleClick(e)]}
        onPointerDown={(e) => [setCount2(count2 + 1), handleClick(e)]}
      >
        halo
      </button>
      <br />
      {number}
      <br />
      {count}
      <br />
      {count2}
      {/* <div id="my_camera" style={{width:320, height:240}}></div>
      <div id="my_result"></div>
      <button onClick={() => take_snapshot()}>Take Snapshot</button> */}
    </div>
  );
}

export default Index;
